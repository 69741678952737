<ex-dropdown *ngIf="context === 'in-list-view'; else wideNoteEditor"
   #narrowNoteEditor id="narrowNoteEditor"
  class="ls-m1"
  size="x-small"
  noInput
  noChevron
  noBorder
  ignoreEnterKey
  [headerBackgroundColor]="displayText ? '' : 'var(--basic-250)' "
  [forceToggle$]="forceToggle$"
  (dropdownToggle)="onDropdownToggle($event)"
  (outsideClicked)="mode = 'view'"
  container="body"
  [overlayPositions]="[
    {originX: 'end', originY: 'bottom', overlayX: 'end', overlayY: 'top'},
    {originX: 'end', originY: 'top', overlayX: 'end', overlayY: 'bottom'}
  ]"
>

  <ng-template exDropdownDisplay>

    <div class="d-flex w-100 align-items-center justify-content-evenly">
      <i class="{{dbNote ? 'ph-fill' : 'ph'}} ph-notepad"></i>
      <span class="note-placeholder" *ngIf="displayText && !isPanelOpen">{{'tenders.note.note-placeholder' | translate}}</span>
    </div>

  </ng-template>

  <ng-template exDropdownContent>
    <div class="d-flex flex-column note-editor">
      <textarea #narrowNoteTextArea id="narrowNoteTextArea"
        [(ngModel)]="note" placeholder="Ecrivez votre note ici..."
                class="border-radius-8px"
                cols="33"
                cdkTextareaAutosize
                cdkAutosizeMinRows="1"
                cdkAutosizeMaxRows="5"
                maxlength="300"
                (focus)="mode = 'edition'; getNote()"
                (blur)="updateNote()"
      ></textarea>
      <div class="footer-part overflow-hidden flex-center-end">
        <div class="btn-container flex-center-end">
          <button class="textarea-button outline-and-rounded"
                  (click)="actionClick($event)">
            <div class="flex-center-center">
              <span *ngIf="mode === 'edition'">{{ 'tenders.note.save' | translate }}</span>
              <span *ngIf="mode === 'view'">{{ 'tenders.note.edit' | translate }}</span>
            </div>
          </button>
        </div>
      </div>
    </div>
  </ng-template>

</ex-dropdown>


<ng-template #wideNoteEditor id="wideNoteEditor">
  <div class="note-container">
    <div class="note-header" (mouseenter)="headerHover = true" (mouseleave)="headerHover = false"
         (click)="!note ? mode = 'edition' : undefined" [ngClass]="{'cursor-pointer': mode === 'view' && !note}"
    >
      <div class="title">
        <i class="ph-fill ph-notepad"></i>
        <span class="fw-700">{{ 'tenders.note.note' | translate | uppercase }}</span>
      </div>
      <ex-button *ngIf="mode === 'view' && !note" icon="plus" size="small" variant="ghost" [hovered]="headerHover">
        {{ 'tenders.note.add-note' | translate }}
      </ex-button>
    </div>

    <textarea #wideNoteTextArea id="wideNoteTextArea"
              *ngIf="mode === 'edition' || note"
              (focus)="getNote()"
              (blur)="
                $event.stopPropagation(); updateNote(); mode = 'view';
                wideNoteTextArea.scrollTo(0,0)
              "
              maxlength="300"
              [autofocus]="!note"
              (mousedown)="mode = 'edition'"
              (ngModelChange)="note$.next(note)"
              [(ngModel)]="note"
              [ngClass]="{'edition': mode === 'edition'}"
    >
    </textarea>
  </div>
</ng-template>
