<div class="position-absolute d-flex w-100 h-100 justify-content-center align-items-center"
     *ngIf="tendersSearchService.inSearch && !tendersSearchService.offset"
>
  <div class="position-absolute d-flex w-100 h-100 justify-content-center align-items-center">
    <ex-overlay-loader class="w-100 h-100"
                       [text]="'tenders.loader.' + tendersSearchService.viewMode | translate"/>
  </div>
</div>

<div class="table-container d-flex flex-column">

  <!-- NO RESULTS/FILTERS -->
  <div *ngIf="!tendersSearchService.tenders?.length" class="no-select no-result-container">
    <ng-container *ngTemplateOutlet="errorDisplay; context: {error: getFirstPreSearchError()}"/>

    <!-- NO RESULTS -->
    <ng-container *ngIf="tendersSearchService.tenders && !tendersSearchService.inSearch && !getFirstPreSearchError()">
      <ng-container *ngTemplateOutlet="errorDisplay; context: {error: getFirstPostSearchError()}"/>
    </ng-container>
  </div>
</div>

<cdk-virtual-scroll-viewport
  #tendersList
  *ngIf="tendersSearchService.tenders && tendersSearchService.tenders.length"
  [itemSize]="91"
  [minBufferPx]="200"
  infiniteScroll
  (endScroll)="loadMore()"
  class="tender-result-container"
  [ngClass]="{'has-scroll': hasScroll()}"
>
  <table class="result-table" aria-label="tenders-list">
    <thead>
      <tr>
        <th class="align-middle">{{ 'tenders.table-column.number' | translate | uppercase }}</th>
        <th class="align-middle">{{ 'tenders.table-column.title' | translate | uppercase }}</th>
        <th class="align-middle">{{ 'tenders.table-column.tender-type' | translate | uppercase }}</th>
        <th class="align-middle">{{ 'tenders.table-column.market-type' | translate | uppercase }}</th>
        <th class="date-header">{{ 'tenders.table-column.remaining-time' | translate | uppercase }}</th>
        <th class="status-header">{{ 'tenders.table-column.tender-status' | translate | uppercase }}</th>
      </tr>
    </thead>
    <tbody>
      <tr class="new-params" *ngIf="newParamsNotActiveYet && tendersModuleService.currentManager.marketWatch?.isOwnedByCurrentUser">
        <td class="new-params-td" colspan="6">
          <div class="d-flex align-items-center justify-content-center">
            <i class="ph ph-clock-clockwise"></i>
            <span>{{ 'tenders.market-watches.new-params-will-be-applied' | translate }}
              {{ 'alert.days.label.' + findNextWatchDay() | translate }}
              {{ 'tenders.market-watches.next' | translate }}
          </span>
          </div>
        </td>
      </tr>
      <tr *cdkVirtualFor="let tender of tendersSearchService.tenders; let i = index"
          class="tender-row"
          [ngClass]="{'editor-is-open': tenderNoteEditorOpenings[tender.id] ?? false}"
          (mouseenter)="currentHoveredElementIndex = i"
          (mouseleave)="currentHoveredElementIndex = -1"
      >

        <!-- Colonne # NUMÉROS -->
        <td class="td-number">
          <div class="number">{{ i + 1 }}</div>
        </td>

        <!-- Colonne OBJET -->
        <td>
          <a class="td-title"
             [innerHTML]="tender.title"
             [routerLink]="tender.id"
             [queryParams]="{from: 'search'}"
             [ngbTooltip]="getSanitizedString(tender.title)"
             placement="bottom auto"
             overflowTooltip
             [overflowThreshold]="2"
             tooltipClass="tender-title-tooltip"
             [openDelay]="500"
             (click)="trackClickedTender(tender)"
             aria-label="link-to-tender-detail"
          >
          </a>
          <div class="info-container">
            <i class="ph-fill ph-users"></i>
            <div class="inquirers-container"
                 [ngbTooltip]="getSanitizedString((tender.inquirers ?? []).join(', '))"
                 placement="bottom auto"
                 overflowTooltip
                 [overflowThreshold]="1"
                 tooltipClass="tender-inquirers-tooltip"
                 [openDelay]="500"
                 [innerHTML]=" (tender.inquirers ?? []).join(', ')"
            >
            </div>
            <i class="ph-fill ph-map-pin"></i>
            <div class="territories-container"
                 [ngbTooltip]="tenderTerritoriesTextService.getCompactString(tender.territories)"
                 placement="bottom auto"
                 overflowTooltip
                 [overflowThreshold]="1"
                 tooltipClass="tender-territories-tooltip"
                 [openDelay]="500"
            >
              {{ tenderTerritoriesTextService.getCompactString(tender.territories) }}
              <span *ngIf="!tender.territories.length"
                    class="color-basic-600 fst-italic">{{ "tenders.not-detected-m" | translate }}</span>
            </div>
          </div>
          <div *ngIf="tender.isSuggestion">
            <app-relevance-feedback [tender]="tender" [topics]="tendersSearchService.selectedTopics"/>
          </div>
        </td>

        <!-- Tender Type Column -->
        <td class="d-flex flex-column">
          <div class="d-flex">
            <app-tender-type-badge *ngIf="tender.tenderType !== 'undetermined'" [tenderType]="tender.tenderType"/>
            <span *ngIf="tender.tenderType === 'undetermined'" class="color-basic-600 fst-italic">{{ "tenders.not-detected-m" | translate }}</span>
            <ex-badge *ngIf="tender.dceProjectUid"
                      backgroundColor="var(--basic-250)"
                      color="var(--basic-600)"
                      borderColor="10%"
                      text="DCE"
                      class="dce-badge"
            />
          </div>
          <div class="text-truncate">
            <span class="day-text" *ngIf="isToday(tender.indexationDate) && !inWatches">{{ 'tenders.today' | translate }}</span>
            <span class="day-text" *ngIf="!isToday(tender.indexationDate) || inWatches">{{ tender.indexationDate.toLocaleDateString() }}</span>
          </div>
        </td>

        <!-- Market type Column -->
        <td class="no-select market-types-column">
          <div class="market-types-container">
            <ng-container *ngFor="let type of (tender.marketType ?? []); let index = index">
              <div class="market-type ls-m1">{{ (type.toLowerCase() | articleTitleCase) ?? '' }}</div>
            </ng-container>
            <span *ngIf="!(tender.marketType ?? []).length"
                  class="color-basic-600 fst-italic">{{ "tenders.not-detected-m" | translate }}</span>
          </div>
        </td>

        <!-- Market type tooltip -->
        <ng-template #tenderTooltipTender>
          <ul class="market-type-list">
            <li *ngFor="let type of (tender.marketType ?? []); let index = index">
              {{ type }}
            </li>
          </ul>
        </ng-template>

        <!-- Remaining Time Column -->
        <td>
          <div class="d-flex">
            <app-remaining-days-badge *ngIf="tender.daysLeft !== undefined"
                                      [days]="tender.daysLeft"
                                      color="gray"
                                      [ngbTooltip]="('tenders.metadata.to'| translate) +
                                                          (hasNonZeroTime(tender.closingDate) ?
                                                              (tender.closingDate | date: 'dd/MM/yyyy à HH:mm') :
                                                              (tender.closingDate | date: 'dd/MM/yyyy')
                                                          )"
                                      [disableTooltip]="!tender.closingDate"
                                      placement="bottom auto"
                                      tooltipClass="tender-remaining-days-tooltip"
            />
          </div>
          <span *ngIf="tender.daysLeft === undefined"
                class="color-basic-600 fst-italic">
            {{ "tenders.not-detected-m" | translate }}
          </span>
        </td>

        <!-- colonne de status -->
        <td class="column-status">
          <div class="d-flex flex-column">
            <div class="d-flex pdb-8px">
              <app-tender-status-selector
                [ngClass]="{'has-no-tender-status': !tender.status?.id}"
                [tender]="tender"
                [tenderStatus]="tender.status"
                [placement]="'left'"
                (statusChange)="statusChange(tender, $event)">
              </app-tender-status-selector>
            </div>

            <div class="d-flex">
              <ex-note-editor
                *ngIf="!tender.hide"
                [ngClass]="{'has-tender-note': tender.note}"
                [service]="apiTendersService.annotation"
                [dbNote]="tender.note ?? ''"
                [entityId]="tender.id"
                (noteChange)="noteChange(tender, $event)"
                (isEditorOpen)="isEditorOpened($event)"
                [style.visibility]="tender.note ? 'visible' : 'collapse'"
                [displayText]="!!tender.note"
              >
              </ex-note-editor>

              <!--empty to avoid vertical mouvement when note-editor rerendered-->
              <div class="empty-div" [style.height.px]="24" *ngIf="tender.hide"></div>

              <app-tender-share
                *ngIf="!tender.hide"
                [tender]="tender"
                displayShareIcon
              >
              </app-tender-share>
            </div>
          </div>
        </td>
      </tr>
    </tbody>
  </table>
  <div class="table-footer no-select"
       [hidden]="inBoardView && tendersSearchService.tenders.length > 0"
       [ngClass]="{'processed-watch': hasScroll() && tendersSearchService.lastPageReached && !tendersSearchService.error}">
    <div class="bg-basic-200 w-100">
      <app-simple-loader [size]="42" [isShown]="loadingMore"/>
    </div>
    <div [hidden]="loadingMore || tendersSearchService.inSearch" class="footer-actions">
      <div [hidden]="tendersSearchService.lastPageReached || !tendersSearchService.error"
           (click)="tendersSearchService.error ? search() : loadMore()" (keydown)="tendersSearchService.error ? search() : loadMore()"
           class="footer-action"
      >
        <mat-icon [hidden]="!tendersSearchService.error" svgIcon="refresh"/>
        <span>
          {{
            (tendersSearchService.error ?
              'tenders.table-footer.load-error' :
              'tenders.table-footer.load-more')
              | translate
          }}
        </span>
      </div>
      <div [hidden]="!hasScroll() || !tendersSearchService.lastPageReached || tendersSearchService.error || !inWatches"
           (click)="scrollToTop()" (keydown)="scrollToTop()"
           class="footer-action"
      >

        <ex-empty-state
          [title]="'tenders.table-footer.processed-watch.message' | translate"
          [svgIcon]="'claping-hands'"
          [paddingTop]="'unset'">

          <div *exEmptyStateContent>
            <div class="">{{ 'tenders.table-footer.processed-watch.description' | translate }}</div>
            <div class="pdt-16px d-flex align-items-center justify-content-center color-primary-500 fw-bold">
              <div>{{ 'tenders.table-footer.return-to-top' | translate }}</div>
              <mat-icon class="color-primary-500" svgIcon="arrow-up-circle"/>
            </div>
          </div>

        </ex-empty-state>
      </div>
    </div>
  </div>
</cdk-virtual-scroll-viewport>

<ng-template #errorDisplay let-error="error">
  <ex-empty-state *ngIf="error"
    [svgIcon]="getIcon(error)"
    [title]="'tenders.error-display.' + error + '.message' | translate">

    <div *exEmptyStateContent class="error-text">

      <!--Cas Erreur avec des liens || actions click -->
      <div *ngIf="error === 'first-day-watch-not-ready'">
        <span class="text">{{ 'tenders.error-display.' + error + '.description.text.' + 0 | translate }}</span>
        <span class="action-link" (click)="addMarketWatchUsers()"
              (keydown)="addMarketWatchUsers()">{{ 'tenders.error-display.' + error + '.description.link-label.' + 0 | translate }}</span>
        <span class="text">{{ 'tenders.error-display.' + error + '.description.text.' + 1 | translate }}</span>
        <span class="action-link" [routerLink]="['/tenders/search']">{{ 'tenders.error-display.' + error + '.description.link-label.' + 1 | translate }}</span>.
      </div>

      <div *ngIf="error === 'today-watch-not-ready'">
          <span class="text">{{ 'tenders.error-display.' + error + '.description.text' | translate }}</span>
          <span class="action-link" [routerLink]="['/tenders/search']">
            {{ 'tenders.error-display.' + error + '.description.link-label' | translate }}</span>
      </div>

      <!--Cas Erreur avec que du text -->
      <span *ngIf="error !== 'first-day-watch-not-ready' && error !== 'today-watch-not-ready'">
        {{ 'tenders.error-display.' + error + '.description' | translate }}</span>
    </div>

  </ex-empty-state>

</ng-template>


